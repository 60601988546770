import React from 'react'
import { toMoney } from 'src/Utils/Utils'

import { KPIsLoader } from '../UIComponents/Loader'

const getKPIsSaleInfo = ({ type, key, amount, request, error, count }) => {
  let info

  if (request) {
    info = <KPIsLoader />
  } else if (error && error.status !== 404) {
    info = error
  } else if (amount && amount > 0) {
    info = toMoney(amount)
  } else if (count && count > 0) {
    info = count
  } else {
    if (type === 'transaction') {
      if (key === 'succeeded') {
        info = 'Poxa, nenhuma venda foi aprovada.'
      } else if (key === 'averageTicket') {
        info = 'Sem vendas aprovadas não sabemos o ticket médio :/'
      } else if (key === 'failed') {
        info = 'Que bom! Nenhuma venda falhada por aqui!'
      } else if (key === 'chargedBack') {
        info = 'Oba! Não sofremos nenhum chargeback.'
      } else if (key === 'dispute') {
        info = 'Que legal! Não sofremos nenhuma disputa.'
      }
    } else if (type === 'transfer') {
      if (key === 'succeeded') {
        info = 'Poxa, nenhuma transferência foi paga.'
      } else if (key === 'failed') {
        info = 'Que bom! Nenhuma transferência falhada por aqui.'
      } else if (key === 'toTheMarketplace') {
        info = 'Que pena! Ninguém transferiu nada para nós :('
      }
    } else if (type === 'seller') {
      if (key === 'enabled') {
        info = 'Nenhum estabelecimento foi habilitado. Vamos vender, galera!'
      } else if (key === 'pending') {
        info = 'Ótimo, nenhum estabelecimento está pendente!'
      } else if (key === 'denied') {
        info = 'Nenhum estabelecimento negado.'
      }
    }
  }

  return info
}

const getCardTitles = (type, key) => {
  const dict = {
    transaction: {
      'succeeded': 'Aprovadas',
      'failed': 'Falhadas',
      'averageTicket': 'Ticket Médio',
      'chargedBack': 'Chargeback',
      'dispute': 'Disputas'
    },
    transfer: {
      'succeeded': 'Pagas',
      'failed': 'Falhadas',
      'toTheMarketplace': 'Feitas para o Marketplace'
    },
    seller: {
      'enabled': 'Habilitados',
      'pending': 'Pendentes',
      'denied': 'Negados'
    }
  }

  return dict[type][key] || key
}

export {
  getKPIsSaleInfo,
  getCardTitles
}
