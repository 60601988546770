import moment from 'moment'
import * as cpf from '@fnando/cpf'
import * as cnpj from '@fnando/cnpj'
import { isObject } from 'lodash'

import history from 'src/Configs/history'
import uriRequests from 'src/Configs/uriRequests'

moment.locale('pt-br')

export const toMoney = (
  value = 0,
  decimals = 2,
  decimalSeparator = ',',
  thousandSeparator = '.',
  currency
) => {
  if (typeof value === 'string') {
    value = value?.replace(',', '.')
    value = parseFloat(value)
  }
  const sign = value < 0 ? '- ' : '' // extracting the absolute value of the integer part of the number and converting to string
  const i = parseInt((value = Math.abs(value).toFixed(decimals))) + ''
  let j = i.length
  j = j > 3 ? j % 3 : 0
  return (
    sign +
    `${typeof currency === 'undefined' ? 'R$ ' : currency}` +
    (j ? i.substr(0, j) + thousandSeparator : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousandSeparator) +
    (decimals
      ? decimalSeparator +
        Math.abs(value - i)
          .toFixed(decimals)
          .slice(2)
      : '')
  )
}

export const toDecimal = (value = 0, decimals = 2, decimalSeparator = ',') => {
  const i = parseInt((value = Math.abs(value).toFixed(decimals))) + ''
  const decimalValue =
    i +
    (decimals
      ? decimalSeparator +
        Math.abs(value - i)
          .toFixed(decimals)
          .slice(2)
      : '')
  return decimalValue
}

export const toPercentage = (value = 0, decimals = 2) =>
  `${value.toFixed(decimals)}%`

export function bytesToSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return 'n/a'
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
  if (i === 0) return `${bytes} ${sizes[i]})`
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`
}

// eslint-disable-next-line no-extend-native
Number.prototype.toMoney = (decimals, decimalSep, thousandsSep, currency) => {
  return toMoney(this, decimals, decimalSep, thousandsSep, currency)
}

export const toPhone = (value) => {
  let phone = null
  if (value.length === 10) {
    phone = `(${value.substring(0, 2)}) ${value.substring(2, 6)}-${value.substring(6, 10)}`
  } else if (value.length === 11) {
    phone = `(${value.substring(0, 2)}) ${value.substring(2, 7)}-${value.substring(7, 11)}`
  } else {
    phone = value
  }
  return phone
}

// eslint-disable-next-line no-extend-native
Number.prototype.toMoney = (decimals, decimalSep, thousandsSep, currency) => {
  return toMoney(this, decimals, decimalSep, thousandsSep, currency)
}

// eslint-disable-next-line no-extend-native
Number.prototype.toMoneyWithoutCurrency = function (
  decimals,
  decimalSep,
  thousandsSep
) {
  let n = this
  const numberOfDecimals = isNaN(decimals) ? 2 : Math.abs(decimals) // if decimal is zero we must take it, it means user does not want to show any decimal
  const decimalSeparator = decimalSep || ',' // if no decimal separator is passed we use the dot as default decimal separator (we MUST use a decimal separator)
  const thousandSeparator =
    typeof thousandsSep === 'undefined' ? '.' : thousandsSep // if you don't want to use a thousands separator you can pass empty string as thousands_sep value
  const sign = n < 0 ? '-' : '' // extracting the absolute value of the integer part of the number and converting to string
  const i = parseInt((n = Math.abs(n).toFixed(numberOfDecimals))) + ''
  let j = i.length
  j = j > 3 ? j % 3 : 0
  return (
    sign +
    (j ? i.substr(0, j) + thousandSeparator : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousandSeparator) +
    (numberOfDecimals
      ? decimalSeparator +
        Math.abs(n - i)
          .toFixed(numberOfDecimals)
          .slice(2)
      : '')
  )
}

// eslint-disable-next-line no-extend-native
Number.prototype.toPercentage = function (decimals) {
  const number = this
  const toFixed = decimals || 2

  return `${number.toFixed(toFixed)}%`
}

// eslint-disable-next-line no-extend-native
String.prototype.withThousandSeparator = function () {
  let formatedNumber = this
  return formatedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

// eslint-disable-next-line no-extend-native
String.prototype.toCPF = function () {
  const string = this
  if (string.length !== 11) return string
  return string.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
}

// eslint-disable-next-line no-extend-native
String.prototype.toCnpj = function () {
  const string = this
  if (string.length !== 14) return string
  return string.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    '$1.$2.$3/$4-$5'
  )
}

// eslint-disable-next-line no-extend-native
String.prototype.toDocument = function () {
  const string = this

  if (string.length === 11)
    return string.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
  if (string.length === 14)
    return string.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      '$1.$2.$3/$4-$5'
    )

  return string
}

// Adding items of array to mySet
// eslint-disable-next-line no-extend-native
Set.prototype.addItems = function (array) {
  array.forEach((item) => this.add(item))
}

/**
 * Function for parsing date into a readable string
 * @param {string} stringDate The date you want to parse
 * @param {string} format In which format you want to parse it, for example "DD/MM/YYYY"
 * @param {string} datezone Datezone to format the date to, for example "+0300"
 * @returns parsed date using momentjs
 */
export function parseDate(stringDate, format, datezone = null) {
  moment.locale('pt')

  const date = moment(stringDate)

  if (datezone) {
    return date.zone(datezone).format(format)
  }

  return date.format(format)
}

export function getStatusLabel(status) {
  const statusObj = {
    active: { label: 'Ativo' },
    canceled: { label: 'Cancelado' },
    deleted: { label: 'Deletado' },
    disabled: { label: 'Inativo' },
    dispute: { label: 'Disputa' },
    disputed: { label: 'Disputa' },
    dispute_succeeded: { label: 'Disputa Ganha' },
    enabled: { label: 'Habilitado' },
    failed: { label: 'Falhado' },
    new: { label: 'Novo' },
    paid: { label: 'Pago' },
    pending: { label: 'Pendente' },
    pre_authorized: { label: 'Pré-autorizado' },
    pre_authorization: { label: 'Pré-autorizada' },
    authorization: { label: 'Autorizada' },
    capture: { label: 'Aprovada' },
    created: { label: 'Criada' },
    refunded: { label: 'Estornado' },
    reversed: { label: 'Revertida' },
    succeeded: { label: 'Aprovado' },
    transfer: { label: 'Transferências' },
    transfer_refunded: { label: 'Transferências estornadas' },
    charge: { label: 'Vendas' },
    charge_refunded: { label: 'Vendas estornadas' },
    deposit: { label: 'Depósitos' },
    deposit_refunded: { label: 'Depósitos estornados' },
    charged_back: { label: 'Chargeback' },
    approved: { label: 'Aprovado' },
    rejected: { label: 'Recusado' },
    void: { label: 'Cancelada' },
    reversal: { label: 'Revertida' },
    expiration: { label: 'Expirado' }
  }
  return statusObj[status] ? statusObj[status].label : ''
}

export function getCardBrandImg(brandName) {
  if (!brandName) {
    return '/assets/icons/cardbrand/unknown.svg'
  }
  return (
    '/assets/icons/cardbrand/' +
    brandName.toLowerCase().replace(' ', '-') +
    '.svg'
  )
}

export function getPaymentType(paymentType, installmentPlan) {
  const dict = {
    wallet: 'Wallet',
    credit: 'Crédito',
    debit: 'Débito',
    boleto: 'Boleto bancário',
    pix: 'Pix',
    voucher: 'Voucher',
    commission: 'Markup',
    nupay: 'Nupay',
    bolepix: 'Boleto com pix'
  }

  const translatedPaymentType = dict[paymentType]

  if (paymentType !== 'credit') {
    return translatedPaymentType
  }

  if (!installmentPlan || parseInt(installmentPlan.number_installments) <= 1) {
    return 'Crédito à vista'
  }

  return `Crédito parcelado ${installmentPlan.number_installments}x`
}

export const nextBusinessDay = (format = 'yyyy-MM-DD') => {
  const dayOfWeek = moment().day()

  const getDaySub = {
    5: 3,
    6: 2,
    0: 1
  }

  const isWeekend = getDaySub[dayOfWeek]

  if (isWeekend == null)
    return moment().add(1, 'day').startOf('day').format(format)

  if (isWeekend) {
    return moment().add(isWeekend, 'day').startOf('day').format(format)
  }
}

export function getPaymentTypeIcon(paymentType) {
  const dict = {
    wallet: '/assets/icons/banking/digital-account.svg',
    credit: '/assets/icons/banking/credit-card.svg',
    debit: '/assets/icons/banking/credit-card.svg',
    voucher: '/assets/icons/banking/credit-card.svg',
    boleto: '/assets/icons/banking/boleto.svg',
    bolepix: '/assets/icons/banking/boleto.svg',
    pix: '/assets/icons/banking/pix.png',
    commission: '/assets/icons/others/tax.svg',
    nupay: '/assets/icons/banking/credit-card.svg'
  }

  return dict[paymentType]
}

export function getFileExtensionIcon(extension) {
  const dict = {
    csv: '/assets/icons/file/file-csv.svg',
    xls: '/assets/icons/file/file-xls.svg',
    xlsx: '/assets/icons/file/file-xls.svg'
  }

  return dict[extension]
}

export function translateTransferTypes(type) {
  switch (type) {
    case 'deposit':
      return 'Depósito'
    case 'adjustment':
      return 'Ajuste'
    case 'transfer':
      return 'Entre contas digitais'
    case 'payout_manual':
      return 'Pagamento manual'
    case 'transfer_and_payout':
      return 'Entre contas digitais e pagamento'
    case 'payout_automatic':
      return 'Pagamento automático'
    case 'charge':
      return 'Venda'
    case 'cash_in':
      return 'Recebida'
    case 'charge_refunded':
      return 'Venda estornada'
    case 'deposit_refunded':
      return 'Depósito estornado'
    default:
      return ''
  }
}

export function translateTransferTypesForGraph(type) {
  switch (type) {
    case 'transfer':
      return 'Entre contas digitais'
    case 'payout_automatic':
      return 'Automática'
    case 'cash_out_automatic':
      return 'Automática'
    case 'payout_manual':
      return 'Enviada'
    case 'cash_out':
      return 'Enviada'
    case 'transfer_and_payout':
      return 'Enviada'
    case 'cash_in':
      return 'Recebida'
    case 'adjustment':
      return 'Ajuste'
    default:
      return ''
  }
}

export function translateTransactionsTypes(type) {
  switch (type) {
    case 'boleto':
      return 'Boleto'
    case 'pix':
      return 'Pix'
    case 'nupay':
      return 'Nupay'
    case 'bolepix':
      return 'Bolepix'
    case 'debit':
      return 'Débito'
    case 'credit':
      return 'Crédito'
    default:
      return ''
  }
}

/**
 * This function returns a deep copy instance of the object passed as parameter
 * @param {*} object object to be copied from
 * @returns deep copy of the object
 */
export function deepCopy(object) {
  return JSON.parse(JSON.stringify(object))
}

export function getTextPlural(key, count) {
  const dict = {
    transfer: {
      singular: 'transferência',
      plural: 'transferências'
    },
    transaction: {
      singular: 'venda',
      plural: 'vendas'
    },
    commission: {
      singular: 'recebível',
      plural: 'recebíveis'
    }
  }

  if (!dict[key]) return key

  return count > 1 ? dict[key].plural : dict[key].singular
}

export function getStatusClass(status) {
  const dict = {
    canceled: 'text-danger',
    pending: 'text-warning',
    pre_authorized: 'text-warning',
    void: 'text-danger',
    paid: 'text-success',
    authorization: 'text-success',
    refunded: 'text-danger',
    succeeded: 'text-success',
    charged_back: 'text-success',
    dispute: 'text-warning',
    new: 'text-success',
    failed: 'text-danger'
  }
  return dict[status]
}

export function isAlias(item, pathOpen) {
  if (!item.aliases || !pathOpen) {
    return false
  }

  const pathOpenSplit = pathOpen.split('/')

  let aliasExists = true

  for (const element of item.aliases) {
    aliasExists = true
    const alias = element
    const aliasSplit = alias.split('/')

    for (let e = 0; e < aliasSplit.length; e++) {
      let param = aliasSplit[e]

      if (param.indexOf(':') === 0) {
        continue
      }

      let curParam = pathOpenSplit[e]

      if (param !== curParam) {
        aliasExists = false
        break
      }
    }

    if (aliasExists) {
      break
    }
  }

  return aliasExists
}

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d)
}

export function isCPF(string) {
  return cpf.isValid(string)
}

export function isCNPJ(string) {
  return cnpj.isValid(string)
}

export function deleteAllFiltersParams() {
  const params = new URLSearchParams(history.location.search)
  let paramsToBeDeleted = []

  for (let param of params) {
    const key = param[0]

    if (key.startsWith('filter_')) {
      paramsToBeDeleted.push(key)
    }
  }

  for (let i = 0; i < paramsToBeDeleted.length; i++) {
    params.delete(paramsToBeDeleted[i])
  }

  history.push({
    search: params.toString()
  })
}

export function appendSearchParams(key, value, pathname) {
  const params = new URLSearchParams(history.location.search)

  params.has(key) ? params.set(key, value) : params.append(key, value)

  history.push({
    pathname,
    search: params.toString()
  })
}

export function getAppliedFilters(history) {
  const params = new URLSearchParams(history.location.search)
  const appliedFilters = {}

  for (let param of params) {
    const key = param[0]
    const value = param[1]

    if (key.startsWith('filter_')) {
      const formattedKey = key.replace('filter_', '')
      appliedFilters[formattedKey] = value
    }
  }

  return appliedFilters
}

export function getDocumentLabel(document) {
  return parseDocument(document).length === 11 ? 'CPF' : 'CNPJ'
}

export function getDocumentKey(document) {
  if (parseDocument(document).length === 11) {
    return 'taxpayer_id'
  } else if (parseDocument(document).length === 14) {
    return 'ein'
  }
}

export function parseDocument(document) {
  return document.replace(/[^0-9]/g, '')
}

export function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1])

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length)

  // create a view into the buffer
  let ia = new Uint8Array(ab)

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([ab], { type: mimeString })
  return blob
}

export const getTranslatedExportType = (exportType) => {
  const mapExportTypes = {
    transactions: 'Vendas',
    transfers: 'Pagamentos',
    entries: 'Extrato'
  }

  return mapExportTypes[exportType]
}

export const isValidJSON = (str) => {
  try {
    JSON.parse(str)
    return true
  } catch {
    return false
  }
}

export const shortenText = (text, maxCharacters = 40) => {
  if (text.length > maxCharacters)
    return `${text.substring(0, maxCharacters)}...`

  return text
}

const enumerateDaysBetweenDates = (startDate, endDate) => {
  let date = [moment(startDate)]

  do {
    startDate = moment(startDate).add(1, 'days')
    date.push(startDate)
  } while (moment(startDate) < moment(endDate).subtract(1, 'days'))

  return date
}

const mountDateHistogramInDays = (dateHistogram, period, subtractPeriod) => {
  let from, to

  if (isObject(period)) {
    from = period.gte
    to = period.lte
  } else {
    from = moment().startOf(period)
    to = moment().endOf(period)

    if (subtractPeriod) {
      from = moment().subtract(1, subtractPeriod).startOf(period)
      to = moment().subtract(1, subtractPeriod).endOf(period)
    }
  }

  const days = enumerateDaysBetweenDates(from, to)

  return days.map((day) => {
    const dateHistogramItem =
      dateHistogram.length &&
      dateHistogram.find((i) => i.key === day.format('DD'))

    if (dateHistogramItem) {
      return {
        name: day.format('ddd'),
        value1: dateHistogramItem.sum_amount.value / 100,
        value2: dateHistogramItem.count,
        interval: day.format('DD/MM')
      }
    }

    return {
      name: day.format('ddd'),
      value1: 0,
      value2: 0,
      interval: day.format('DD/MM')
    }
  })
}

const mountDateHistogramInMonths = (dateHistogram) => {
  return dateHistogram
    .reduceRight((acc, curr) => {
      let date = moment()
        .set('month', parseInt(curr.key) - 1)
        .locale('pt-br')

      const checkIfMonthIsSetted = acc.find(
        (month) => month.name === date.format('MMM').capitalize()
      )
      const monthGTECurrent =
        parseInt(date.format('M')) > parseInt(moment().format('M'))

      if (checkIfMonthIsSetted || monthGTECurrent) {
        date.subtract(1, 'year')
        return [
          ...acc,
          {
            name: date.format('MMM').capitalize(),
            value1: curr.sum_amount.value / 100,
            value2: curr.count,
            interval: date.format('MMMM [de] YYYY').capitalize()
          }
        ]
      }

      return [
        ...acc,
        {
          name: date.format('MMM').capitalize(),
          value1: curr.sum_amount.value / 100,
          value2: curr.count,
          interval: date.format('MMMM [de] YYYY').capitalize()
        }
      ]
    }, [])
    .reverse()
}

const mountDateHistogramInHour = (dateHistogram, period) => {
  let historic = []

  historic.push({
    name: '00',
    value1: 0,
    value2: 0,
    interval: '0h - 1h',
    label: '0h'
  })
  historic.push({ name: '01', value1: 0, value2: 0, interval: '1h - 2h' })
  historic.push({ name: '02', value1: 0, value2: 0, interval: '2h - 3h' })
  historic.push({ name: '03', value1: 0, value2: 0, interval: '3h - 4h' })
  historic.push({
    name: '04',
    value1: 0,
    value2: 0,
    interval: '4h - 5h',
    label: '4h'
  })
  historic.push({ name: '05', value1: 0, value2: 0, interval: '5h - 6h' })
  historic.push({ name: '06', value1: 0, value2: 0, interval: '6h - 7h' })
  historic.push({ name: '07', value1: 0, value2: 0, interval: '7h - 8h' })
  historic.push({
    name: '08',
    value1: 0,
    value2: 0,
    interval: '8h - 9h',
    label: '8h'
  })
  historic.push({ name: '09', value1: 0, value2: 0, interval: '9h - 10h' })
  historic.push({ name: '10', value1: 0, value2: 0, interval: '10h - 11h' })
  historic.push({ name: '11', value1: 0, value2: 0, interval: '11h - 12h' })
  historic.push({
    name: '12',
    value1: 0,
    value2: 0,
    interval: '12h - 13h',
    label: '12h'
  })
  historic.push({ name: '13', value1: 0, value2: 0, interval: '13h - 14h' })
  historic.push({ name: '14', value1: 0, value2: 0, interval: '14h - 15h' })
  historic.push({ name: '15', value1: 0, value2: 0, interval: '15h - 16h' })
  historic.push({
    name: '16',
    value1: 0,
    value2: 0,
    interval: '16h - 17h',
    label: '16h'
  })
  historic.push({ name: '17', value1: 0, value2: 0, interval: '17h - 18h' })
  historic.push({ name: '18', value1: 0, value2: 0, interval: '18h - 19h' })
  historic.push({ name: '19', value1: 0, value2: 0, interval: '19h - 20h' })
  historic.push({
    name: '20',
    value1: 0,
    value2: 0,
    interval: '20h - 21h',
    label: '20h'
  })
  historic.push({ name: '21', value1: 0, value2: 0, interval: '21h - 22h' })
  historic.push({ name: '22', value1: 0, value2: 0, interval: '22h - 23h' })
  historic.push({ name: '23', value1: 0, value2: 0, interval: '23h - 24h' })
  historic.push({
    name: '24',
    value1: 0,
    value2: 0,
    interval: '24h',
    label: '24h'
  })

  historic = historic.map((item) => {
    if (period > 1) {
      const dateHistogramItem =
        dateHistogram.length && dateHistogram.find((i) => i.key === item.name)
      return {
        name: item.label || item.name,
        value1: dateHistogramItem
          ? dateHistogramItem.sum_amount.value / 100
          : item.value1 / 100,
        value2: dateHistogramItem ? dateHistogramItem.count : item.value2,
        interval: item.interval
      }
    } else {
      const dateHistogramItem =
        dateHistogram.length && dateHistogram.find((i) => i.key === item.name)
      return {
        name: item.label || undefined,
        value1: dateHistogramItem
          ? dateHistogramItem.sum_amount.value / 100
          : item.value1 / 100,
        value2: dateHistogramItem ? dateHistogramItem.count : item.value2,
        interval: item.interval
      }
    }
  })

  return historic
}

export const parseDateHistogram = (dateHistogram, period = 0, isYear) => {
  let historic = []

  if (isObject(period)) {
    const dateDiff = moment(period.lte).diff(moment(period.gte), 'days')

    if (dateDiff >= 0 && dateDiff < 2) {
      historic = mountDateHistogramInHour(dateHistogram, period)
    }

    if (dateDiff > 2 && dateDiff < 31) {
      historic = mountDateHistogramInDays(dateHistogram, period)
    }

    if (dateDiff > 31) {
      historic = mountDateHistogramInMonths(dateHistogram)
    }
  }

  if (isYear) {
    historic = dateHistogram.map((historic) => {
      const date = moment(historic.key).locale('pt-br')
      return {
        name: date.format('MMM').capitalize(),
        value1: historic.sum_amount.value / 100,
        value2: historic.count,
        interval: date.format('MMMM [de] YYYY').capitalize()
      }
    })

    // God is tired of me
    // Essa parte do código serve para "centralizar" as barras do gráfico quando houver menos de 12
    for (let i = 12; i > historic.length; ) {
      const emptyBar = {
        name: null,
        value1: null,
        value2: null
      }

      if (historic.length % 2 === 0) {
        historic.unshift(emptyBar)
      } else {
        historic.push(emptyBar)
      }
    }
  }

  return historic
}

export const momentCheckIfTodayOrYesterday = (date, parse = false) => {
  const dateFormatted = moment(date)

  const today = moment()
  const yesterday = moment().subtract(1, 'day')

  if (dateFormatted.isSame(today, 'day')) {
    return 'Hoje'
  } else if (dateFormatted.isSame(yesterday, 'day')) {
    return 'Ontem'
  }

  if (parse) {
    return parseDate(date, 'DD/MM/YYYY')
  }
}

export const momentToFixedPeriod = (period) => {
  const options = [
    {
      value: 0,
      label: 'Hoje',
      date: {
        gte: moment().startOf('day'),
        lte: moment().endOf('day')
      }
    },
    {
      value: 1,
      label: 'Ontem',
      date: {
        gte: moment().subtract(1, 'day').startOf('day'),
        lte: moment().subtract(1, 'day').endOf('day')
      }
    },
    {
      value: 'current-week',
      label: 'Essa semana',
      date: {
        gte: moment().startOf('isoWeek'),
        lte: moment().endOf('isoWeek')
      }
    },
    {
      value: 'last-week',
      label: 'Semana passada',
      date: {
        gte: moment().subtract(1, 'weeks').startOf('isoWeek'),
        lte: moment().subtract(1, 'weeks').endOf('isoWeek')
      }
    },
    {
      value: 'current-month',
      label: 'Esse mês',
      date: {
        gte: moment().startOf('month'),
        lte: moment().endOf('day')
      }
    },
    {
      value: 'last-month',
      label: 'Mês passado',
      date: {
        gte: moment().subtract(1, 'months').startOf('month'),
        lte: moment().subtract(1, 'months').endOf('month')
      }
    },
    {
      value: 'one-year',
      label: 'Até um ano',
      date: {
        gte: moment().subtract(1, 'year').startOf('day'),
        lte: moment().endOf('day')
      }
    }
  ]

  let periodKey = 'custom-date'

  Object.keys(options).forEach((key) => {
    const gteEquals = options[key].date.gte.isSame(period.gte)
    const lteEquals = options[key].date.lte.isSame(period.lte)

    if (gteEquals && lteEquals) periodKey = options[key].value
  })

  return periodKey
}

export const isLocal = () => process.env.NODE_ENV === 'development'

export const getAddressFromPostalCode = async (postalCode) => {
  const response = await fetch(
    uriRequests.getAddressFromPostalCode.replace('{zipCode}', postalCode)
  )
  return await response.json()
}

export const removeAccount = (
  accountToRemove,
  accounts,
  softRemove = false
) => {
  return accounts.reduce((acc, current) => {
    if (current.userId !== accountToRemove.userId) {
      return [...acc, current]
    } else if (current.marketplaceId !== accountToRemove.marketplaceId) {
      return [...acc, current]
    } else if (softRemove) {
      return [
        ...acc,
        {
          ...current,
          disconnected: true
        }
      ]
    } else {
      return acc
    }
  }, [])
}

export const UppercaseFirstLetter = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export const formatAddress = (address) => {
  const addressFormated = {
    line1: address.line1 ? `${address.line1},` : '',
    line2: address.line2 ? ` ${address.line2},` : '',
    neighborhood: address.neighborhood ? ` ${address.neighborhood},` : '',
    city: address.city ? ` ${address.city}` : '',
    state: address.state ? ` - ${address.state}` : ''
  }
  return `${addressFormated.line1}${addressFormated.line2}${addressFormated.neighborhood}${addressFormated.city}${addressFormated.state}`
}

export const maskEmail = (email) => {
  const splitted = email.split('@')
  const lettersToHide = splitted[0].length / 2
  const first5 = splitted[0].substring(0, lettersToHide)
  const mask = splitted[0]
    .substring(lettersToHide, splitted[0].length)
    .replace(/\D/g, '*')
  return `${first5}${mask}@${splitted[1]}`
}

export const MOBILE_MAX_WIDTH = 1024
export const PHONE_MAX_WIDTH = 480
export const PHONE = 400
export const PHABLET = 560
export const TABLET_SMALL = 640
export const TABLET = 768
export const TABLET_WIDE = 1024
export const DESKTOP = 1248
export const DESKTOP_WIDE = 1440
