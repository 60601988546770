import moment from 'moment'

export default class Utilities {
  static tabsMap () {
    const ret = {
      'marketplace.lists': [],
      'sellers.lists': [],
      'footer.marketplace': [],
      'footer.sellers': [],
      'exports': []
    }

    if (localStorage.getItem('ngStorage-perm_list_transactions_marketplace')) {
      ret['marketplace.lists'].push(
        {
          label: 'Vendas',
          state: 'transactions.marketplace',
          stateName: 'transactions',
          active: true
        }
      )
    }

    if (localStorage.getItem('ngStorage-perm_list_transfers_marketplace')) {
      ret['marketplace.lists'].push(
        {
          label: 'Transferências',
          state: 'transfers.marketplace',
          stateName: 'transfers',
          active: false
        }
      )
    }

    if (localStorage.getItem('ngStorage-perm_list_sellers')) {
      ret['marketplace.lists'].push(
        {
          label: 'Estabelecimentos',
          state: 'sellers',
          stateName: 'sellers',
          active: false
        }
      )
    }

    if (localStorage.getItem('ngStorage-perm_list_plans')) {
      ret['marketplace.lists'].push(
        {
          label: 'Planos de venda',
          state: 'plans',
          stateName: 'plans',
          active: false
        }
      )
    }

    if (localStorage.getItem('ngStorage-perm_list_exports')) {
      ret['marketplace.lists'].push(
        {
          label: 'Listas Exportadas',
          state: 'exportedLists.marketplace',
          stateName: 'exportedLists',
          active: false
        }
      )
    }

    if (localStorage.getItem('ngStorage-perm_list_balances_seller')) {
      ret['sellers.lists'].push(
        {
          label: 'Extrato',
          state: 'balances',
          stateName: 'balances',
          active: false
        }
      )
    }

    if (localStorage.getItem('ngStorage-perm_list_transactions_seller')) {
      ret['sellers.lists'].push(
        {
          label: 'Vendas',
          state: 'transactions.sellers',
          stateName: 'transactions',
          active: false
        }
      )
    }

    if (localStorage.getItem('ngStorage-perm_list_transfers_seller')) {
      ret['sellers.lists'].push(
        {
          label: 'Transferências',
          state: 'transfers.sellers',
          stateName: 'transfers',
          active: false
        }
      )
    }

    if (localStorage.getItem('ngStorage-perm_list_future_transfers_seller')) {
      ret['sellers.lists'].push(
        {
          label: 'Lançamentos Futuros',
          state: 'futureTransfers.sellers',
          stateName: 'futureTransfers',
          active: false
        }
      )
    }

    if (localStorage.getItem('ngStorage-perm_list_exports')) {
      ret['sellers.lists'].push(
        {
          label: 'Listas Exportadas',
          state: 'exportedLists.sellers',
          stateName: 'exportedLists',
          active: false
        }
      )
    }

    if (localStorage.getItem('ngStorage-perm_list_transactions_marketplace')) {
      ret['footer.marketplace'].push(
        {
          label: 'Vendas',
          state: 'transactions.marketplace',
          stateName: 'transactions',
          active: false
        }
      )
    }

    if (localStorage.getItem('ngStorage-perm_list_transfers_marketplace')) {
      ret['footer.marketplace'].push(
        {
          label: 'Transferências',
          state: 'transfers.marketplace',
          stateName: 'transfers',
          active: false
        }
      )
    }

    if (localStorage.getItem('ngStorage-perm_list_sellers')) {
      ret['footer.marketplace'].push(
        {
          label: 'Estabelecimentos',
          state: 'sellers',
          stateName: 'sellers',
          active: false
        }
      )
    }

    ret['footer.marketplace'].push(
      {
        label: 'Configurações',
        state: 'accountConfig.marketplace',
        stateName: 'config',
        active: false
      }
    )

    if (localStorage.getItem('ngStorage-perm_list_transactions_seller')) {
      ret['footer.sellers'].push(
        {
          label: 'Vendas',
          state: 'transactions.sellers',
          stateName: 'transactions',
          active: false
        }
      )
    }

    if (localStorage.getItem('ngStorage-perm_list_transfers_seller')) {
      ret['footer.sellers'].push(
        {
          label: 'Transferências',
          state: 'transfers.sellers',
          stateName: 'transfers',
          active: false
        }
      )
    }

    ret['footer.sellers'].push(
      {
        label: 'Configurações',
        state: 'accountConfig.sellers',
        stateName: 'config',
        active: false
      }
    )

    ret['marketplace.config'] = [
      {
        label: 'Cadastro',
        state: 'accountConfig.marketplace',
        stateName: 'account',
        active: false
      }
    ]

    if (localStorage.getItem('ngStorage-perm_create_bank_account_marketplace') ||
      localStorage.getItem('ngStorage-perm_create_bank_account_seller') ||
      localStorage.getItem('ngStorage-perm_list_bank_accounts')) {
      ret['marketplace.config'].push(
        {
          label: 'Transferências',
          state: 'bankAccountsConfig.marketplace',
          stateName: 'bankAccounts',
          active: false
        }
      )
    }

    if (localStorage.getItem('ngStorage-perm_list_users') ||
      localStorage.getItem('ngStorage-perm_delete_user') ||
      localStorage.getItem('ngStorage-perm_create_user')) {
      ret['marketplace.config'].push(
        {
          label: 'Usuários',
          state: 'usersConfig',
          stateName: 'users',
          active: false
        }
      )
    }

    if (localStorage.getItem('ngStorage-perm_list_webhooks') || localStorage.getItem('ngStorage-perm_create_webhook')) {
      ret['marketplace.config'].push(
        {
          label: 'Webhooks',
          state: 'webhooksConfig',
          stateName: 'webhooks',
          active: false
        }
      )
    }

    if (localStorage.getItem('ngStorage-perm_list_api_keys') || localStorage.getItem('ngStorage-perm_create_api_key')) {
      ret['marketplace.config'].push(
        {
          label: 'API Keys',
          state: 'apiKeyConfig',
          stateName: 'apiKey',
          active: false
        }
      )
    }

    if (localStorage.getItem('ngStorage-perm_custom_theme')) {
      ret['marketplace.config'].push(
        {
          label: 'Personalização',
          state: 'customizeConfig',
          stateName: 'customize',
          active: false
        }
      )
    }

    ret['sellers.config'] = [
      {
        label: 'Cadastro',
        state: 'accountConfig.sellers',
        stateName: 'account',
        active: false
      },
      {
        label: 'Transferências',
        state: 'bankAccountsConfig.sellers',
        stateName: 'bankAccounts',
        active: false
      }
    ]

    if (localStorage.getItem('ngStorage-perm_insert_document')) {
      ret['sellers.config'].push(
        {
          label: 'Documentos',
          state: 'documentsConfig',
          stateName: 'documents',
          active: false
        }
      )
    }

    if (localStorage.getItem('ngStorage-perm_details_subscription')) {
      ret['sellers.config'].push(
        {
          label: 'Planos',
          state: 'plansConfig',
          stateName: 'plans',
          active: false
        }
      )
    }

    if (localStorage.getItem('ngStorage-perm_list_exports') || localStorage.getItem('ngStorage-perm_list_export_transactions')) {
      ret['exports'].push(
        {
          label: 'Lista de Vendas',
          type: 'transactions'
        }
      )
    }

    if (localStorage.getItem('ngStorage-perm_list_exports') || localStorage.getItem('ngStorage-perm_list_export_balances')) {
      ret['exports'].push(
        {
          label: 'Lista de Extratos',
          type: 'entries'
        }
      )
    }

    if (localStorage.getItem('ngStorage-perm_list_exports') || localStorage.getItem('ngStorage-perm_list_export_transfers')) {
      ret['exports'].push(
        {
          label: 'Lista de Transferências',
          type: 'transfers'
        }
      )
    }

    return ret
  }

  static _buildTabsMap (tabActive, tabObj, tabParams) {
    if (tabObj) {
      return tabObj.map(tab => {
        tab.active = tab.stateName === tabActive
        tab.params = tabParams
        return tab
      })
    }
    return []
  }

  static getTabsMap (stateType, stateName, params) {
    let statePrefix = stateName.indexOf('.') >= 0 ? stateName.slice(0, stateName.indexOf('.')) : stateName

    if ((/Details/).test(statePrefix)) { // Tratamento p/ states de details
      statePrefix = statePrefix.slice(0, statePrefix.indexOf('Details')) + 's'
    }

    if (/Config/.test(statePrefix)) {
      statePrefix = statePrefix.slice(0, statePrefix.indexOf('Config'))

      if ((/footer/).test(stateType)) {
        statePrefix = 'config'
      }
    }
    return this._buildTabsMap(statePrefix, Utilities.tabsMap()[stateType], params)
  }

  static buildParams (params, isPaged, page) {
    let paramsObj = params
    for (let prop in params) {
      if (params.hasOwnProperty(prop) && prop !== '#' && prop !== 'page') {
        paramsObj[prop] = params[prop]
      }
    }

    if (isPaged) {
      paramsObj.page = page
    } else {
      paramsObj.page = 1
    }

    return paramsObj
  }
}
// Capitalize string
// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1)
}

export function getCreditCardBrandImg (brandName) {
  return `assets/imgs/${brandName.toLowerCase().replace(' ', '-')}.png`
}

// eslint-disable-next-line no-extend-native
Number.prototype.toMoney = function (decimals, decimalSep, thousandsSep) {
  let n = this
  const numberOfDecimals = isNaN(decimals) ? 2 : Math.abs(decimals) // if decimal is zero we must take it, it means user does not want to show any decimal
  const decimalSeparator = decimalSep || ',' // if no decimal separator is passed we use the dot as default decimal separator (we MUST use a decimal separator)
  const thousandSeparator = (typeof thousandsSep === 'undefined') ? '.' : thousandsSep // if you don't want to use a thousands separator you can pass empty string as thousands_sep value
  const sign = (n < 0) ? '-' : '' // extracting the absolute value of the integer part of the number and converting to string
  const i = parseInt(n = Math.abs(n).toFixed(numberOfDecimals)) + ''
  let j = i.length
  j = (j > 3) ? j % 3 : 0
  return sign + ' R$ ' + (j ? i.substr(0, j) + thousandSeparator : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousandSeparator) + (numberOfDecimals ? decimalSeparator + Math.abs(n - i).toFixed(numberOfDecimals).slice(2) : '')
}

// eslint-disable-next-line no-extend-native
Number.prototype.toPercentage = function (decimals) {
  const number = this
  const toFixed = decimals || 2

  return `${number.toFixed(toFixed)}%`
}

//
// Convert um cor hex em rgba
//
const hex2rgb = (hex) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16))
  return `${r},${g},${b}`
}

export function setUserStyles (color) {
  let bodyElement = window.document.getElementsByTagName('body')[0]
  let styles = window.document.querySelector('#dash-styles') || window.document.createElement('style')

  styles.type = 'text/css'
  styles.id = 'dash-styles'

  styles.innerHTML = `
      :root {
          --base-rgb: ${hex2rgb(color)};
      }
  `

  if (window.document.querySelector('#dash-styles')) {
    window.document.querySelector('#dash-styles').replaceWith(styles)
  } else {
    bodyElement.appendChild(styles)
  }
}

export function validateCPF (cpf) {
  cpf = cpf.replace(/[^\d]+/g, '')
  if (!cpf || cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999') { return false }
  let soma = 0
  let resto
  for (let i = 1; i <= 9; i++) { soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i) }
  resto = (soma * 10) % 11
  if ((resto === 10) || (resto === 11)) resto = 0
  if (resto !== parseInt(cpf.substring(9, 10))) return false
  soma = 0
  for (let i = 1; i <= 10; i++) { soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i) }
  resto = (soma * 10) % 11
  if ((resto === 10) || (resto === 11)) resto = 0
  if (resto !== parseInt(cpf.substring(10, 11))) return false
  return true
}

export function validateCNPJ (cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, '')

  if (cnpj === '') return false

  if (cnpj.length !== 14) { return false }

  // Elimina CNPJs invalidos conhecidos
  if (cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999') { return false }

  // Valida DVs
  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho)
  let digitos = cnpj.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) { pos = 9 }
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
  if (resultado !== digitos.charAt(0)) { return false }

  tamanho = tamanho + 1
  numeros = cnpj.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) { pos = 9 }
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
  if (resultado !== digitos.charAt(1)) { return false }

  return true
}

export function _getLteTime (period, convertUTC = false) {
  const defaultTime = { 'hour': 23, 'minute': 59, 'second': 59 }
  const dateFormat = 'YYYY-MM-DDTHH:mm:ss'
  let periodDate

  if (period === '0d' || period === '1d') {
    periodDate = moment().set(defaultTime).subtract(period.replace(/\D/g, ''), 'day')
  } else {
    periodDate = moment(defaultTime)
  }

  return convertUTC
    ? periodDate.utc().format(dateFormat)
    : periodDate.format(dateFormat)
}

export function _getGteDate (period, convertUTC = false) {
  const defaultTime = { 'hour': 0, 'minute': 0, 'second': 0 }
  const dateFormat = 'YYYY-MM-DDTHH:mm:ss'
  let periodDate

  if (period === '0d' || period === '1d') {
    periodDate = moment().set(defaultTime).subtract(period.replace(/\D/g, ''), 'day')
  } else if (period.includes('d')) {
    periodDate = moment().set(defaultTime).subtract(period.replace(/\D/g, '') - 1, 'day')
  } else if (period === '3M') {
    periodDate = moment().set(defaultTime).subtract(3, 'month')
  }

  return convertUTC
    ? periodDate.utc().format(dateFormat)
    : periodDate.format(dateFormat)
}

export function _getPeriodConfig (period) {
  const periodObj = {
    'y': 'year',
    'M': 'month',
    'd': 'day'
  }

  return periodObj[period]
}

export function _getPeriodFormat (period) {
  const periodObj = {
    'y': 'yyyy',
    'M': 'MM',
    'd': 'dd/MM/yyyy'
  }
  return periodObj[period]
}

/**
 * Function to generate a random number in a range
 * @param {Number} min minimum value to generate a number from
 * @param {Number} max maximum value to generate a number from
 * @returns {Number} generated number between min and max params
 */
function getRandomArbitrary (min, max) {
  return Math.floor(Math.random() * (max - min) + min)
}

/**
 * Function to generate a temporary password for user creation
 * @param {Number} passwordLength The number of chars that the password will have
 * @returns {string} Randomic password generated
 */
export function generatePassword (passwordLength) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+-='

  let newPassword = ''
  let lastChar = null
  // Variable that defines each character type beginning and ending
  let maxMin = [{ min: 0, max: 26 }, { min: 26, max: 52 }, { min: 52, max: 62 }, { min: 62, max: 74 }]

  for (let i = 0; i < passwordLength; i++) {
    const rand4 = Math.floor((Math.random() * maxMin.length))
    const charIndex = getRandomArbitrary(maxMin[rand4].min, maxMin[rand4].max)
    maxMin.splice(rand4, 1)

    let char = characters.charAt(charIndex)

    // Avoid character repetition
    if (char === lastChar) {
      if (charIndex === characters.length) {
        char = characters.charAt(charIndex - 1)
      } else {
        char = characters.charAt(charIndex + 1)
      }
    }

    lastChar = char
    newPassword += char

    if (maxMin.length === 0) {
      maxMin = [{ min: 0, max: 26 }, { min: 26, max: 52 }, { min: 52, max: 62 }, { min: 62, max: 74 }]
    }
  }

  return newPassword
}
