import { panelStore } from 'src/Store/panelStore'
import uriRequests from 'src/Configs/uriRequests'
import { verifyToken } from 'src/Actions/authentication'
import appConfig from 'src/Configs/appConfig'
import { datadogRum } from '@datadog/browser-rum'

export const insertHeaders = (config) => {
  const {
    authentication,
    marketplace: rootMarketplace,
    context: { seller: contextSeller, marketplace: contextMarketplace },
    user: { id: userId }
  } = panelStore.getState()

  const marketplace =
    (!config.useRootMarketplace && contextMarketplace) || rootMarketplace
  const token = authentication?.token

  if (
    token &&
    !config.headers.hasOwnProperty('Authorization') &&
    !config.ignoreAuthorization
  ) {
    config.headers['Authorization'] = `Bearer ${token} ${marketplace.id}`
  }

  if (appConfig.XAPIKey) {
    config.headers['X-Api-Key'] = appConfig.XAPIKey
  }

  config.headers['Content-Type'] =
    config.headers['Content-Type'] || 'application/json'

  datadogRum.setRumGlobalContext({
    marketplaceId: marketplace && marketplace.id ? marketplace.id : 'null',
    sellerId: contextSeller?.id || 'null',
    userId: userId || 'null'
  })

  return config
}

export const insertUserIdHeader = (config) => {
  const { user } = panelStore.getState()

  if (user?.id) {
    config.headers['user-id'] = user.id
  }

  return config
}

export const insertMFAHeaders = (config) => {
  const {
    authentication: { mfaSessionToken }
  } = panelStore.getState()

  if (mfaSessionToken) {
    config.headers['x-mfa-token'] = mfaSessionToken
  }

  return config
}

export const insertSellerAndMarketplaceContext = (config) => {
  const {
    marketplace: rootMarketplace,
    context: { seller: contextSeller, marketplace: contextMarketplace }
  } = panelStore.getState()

  const sellersContextualizedUrls = [
    uriRequests.getMarketplaceTransactions,
    uriRequests.getMarketplaceTransfers,
    uriRequests.getMetrics,
    uriRequests.getSellerStats,
    uriRequests.getSellerBlockedBalance,
    uriRequests.sellerSubscriptions,
    uriRequests.getSellerDetails,
    uriRequests.getFutureTransfers,
    uriRequests.getSellerBankingBalances,
    uriRequests.getSellerPaymentsBalances
  ]

  const urlsWithoutMarketplaceContext = [
    uriRequests.checkToken,
    uriRequests.getMarketplaceIdBySlug
  ]

  const marketplace =
    (!config.useRootMarketplace && contextMarketplace) || rootMarketplace

  if (
    !config.ignoreSellerAndMarketplaceContext &&
    marketplace &&
    marketplace.id
  ) {
    if (
      sellersContextualizedUrls.includes(config.url) &&
      contextSeller?.id &&
      !config.bypassSellerContext
    ) {
      config.url = `marketplaces/${marketplace.id}/sellers/${contextSeller.id}/${config.url}`
    } else if (
      config.url &&
      !urlsWithoutMarketplaceContext.includes(config.url)
    ) {
      config.url = `marketplaces/${marketplace.id}/${config.url}`
    } else if (
      !config.url &&
      !urlsWithoutMarketplaceContext.includes(config.url)
    ) {
      config.url = `marketplaces/${marketplace.id}`
    }
  }

  return config
}

export const fulfillMiddleware = (res) => {
  if (res && res.data && res.data.error) throw res.data.error

  return res
}

export const rejectMiddleware = (error) => {
  if (
    error.response &&
    [401, 403].includes(error.response.status) &&
    !error.config.url.endsWith(uriRequests.checkToken)
  ) {
    const {
      authentication: { isLoggedIn, token, tokenValidateRequesting }
    } = panelStore.getState()

    if (isLoggedIn && token && !tokenValidateRequesting) {
      panelStore.dispatch(verifyToken(token))
    }
  }

  throw error
}
