export const loadTrackSale = (values) => {
  const existingScript = document.getElementById('trackSale')
  if (!existingScript) {
    const script = document.createElement('script')
    script.src = 'https://cdn.tracksale.co/tracksale-js/tracksale.js'
    script.id = 'trackSale'
    document.body.appendChild(script)
    script.onload = () => {
      try {
        // eslint-disable-next-line no-undef
        const callTrackSale = new Tracksale()
        callTrackSale.init(values)
      } catch (error) {
        console.error('error', error)
      }
    }
  }
}
