import moment from 'moment'

export const getRangeDateForPeriod = (period) => {
  const dateFormat = 'YYYY-MM-DDTHH:mm:ss'

  if (!period) {
    period = {
      lte: moment().format(dateFormat),
      gte: moment().format(dateFormat)
    }
  }

  const dateDiff = moment(period.lte).diff(moment(period.gte), 'days')

  let formatAndInterval = {}

  if (dateDiff >= 0 && dateDiff < 2) {
    formatAndInterval = {
      format: 'HH',
      interval: '1h'
    }
  }

  if (dateDiff > 2 && dateDiff < 31) {
    formatAndInterval = {
      format: 'dd',
      interval: '1d'
    }
  }

  if (dateDiff > 31) {
    formatAndInterval = {
      format: 'MM',
      interval: '1M'
    }
  }

  return {
    gte: moment(period.gte).format(dateFormat),
    lte: moment(period.lte).format(dateFormat),
    ...formatAndInterval
  }
}
