import React from 'react'
import { toast } from 'react-toastify'
import ReactSVG from 'react-svg'
import styled from 'styled-components'

import {
  white,
  darkGray,
  green,
  red,
  normalText,
  smallMargin,
  yellow
} from '../Styles/settings/Constants'

const types = {
  error: { icon: '/assets/icons/alerts/alert.svg', color: red },
  warning: { icon: '/assets/icons/alerts/alert.svg', color: yellow },
  success: { icon: '/assets/icons/alerts/success.svg', color: green }
}

const createToast = (type, message, id, status, { ...props } = {}) => {
  if (toast.isActive(id)) return

  toast[type](
    <Container key={id} data-test={`toast-${id}`}>
      <Icon type={type} src={types[type].icon} />
      <Message
        dangerouslySetInnerHTML={{
          __html:
            status && type === 'error'
              ? `${message} | ERRO <strong>${status}</strong>`
              : message
        }}
      />
      <CloseIcon
        src='/assets/icons/actions/close.svg'
        data-test={`toast-${id}-btn-close`}
      />
    </Container>,
    {
      className: `toast-custom-container ${type}`,
      position: toast.POSITION.TOP_RIGHT,
      closeButton: false,
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      toastId: id,
      ...props
    }
  )
}

const Container = styled.div`
  background-color: ${white};
  border-radius: 5px;
  border: none;
  display: flex;
  height: 100%;
  width: 100%;
`

const CloseIcon = styled(ReactSVG)`
  margin-top: 0.1rem;
  margin-right: ${smallMargin};
  margin-left: ${smallMargin};
  svg {
    fill: ${darkGray};
    height: 10px;
    width: 10px;
  }
`

const Icon = styled(ReactSVG)`
  margin-right: ${smallMargin};
  align-items: center;
  display: flex;
  svg {
    fill: ${({ type }) => types[type].color};
    width: 25px;
    height: 25px;
  }
`

const Message = styled.p`
  font-size: ${normalText};
  color: ${darkGray};
`

export default createToast
