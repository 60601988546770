import axios from 'axios'
import { injectApiVersioningInterceptor } from './interceptor'
import { VersioningStrategy } from './constants'

const defaultWithVersioningConfig = {
  mediaTypeKeyName: 'v',
  queryStringKeyName: 'api-version'
}

const withVersioning = (instance, config) => {
  // merge default config options
  const versioningConfig = {
    ...defaultWithVersioningConfig,
    ...config
  }

  // clone the instance so we don't affect it in any way
  let clonedInstance = axios.create(instance.defaults)

  // add required api versioning interceptor
  injectApiVersioningInterceptor(clonedInstance, versioningConfig)

  return clonedInstance
}

export {
  withVersioning,
  VersioningStrategy
}
