import t from 'tcomb-form'
import moment from 'moment'
import * as cpf from '@fnando/cpf'
import * as cnpj from '@fnando/cnpj'
import { AccountTypes } from '../Constants/AccountTypes'
import { StateTypes } from '../Constants/StateTypes'

const Email = t.refinement(t.String, email => {
  const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return reg.test(email)
})

Email.getValidationErrorMessage = (value) => {
  const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (!reg.test(value)) return 'Você digitou um e-mail válido?'

  return ''
}

const Name = t.refinement(t.String, name => {
  const reg = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/
  return reg.test(name)
})

Name.getValidationErrorMessage = (value) => {
  const reg = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/
  if (!reg.test(value)) return 'Nome inválido'

  return ''
}

const Slug = t.refinement(t.String, name => {
  const reg = /[^A-Za-z0-9,\-,_]/
  return name && name.length > 0 && !reg.test(name)
})

Slug.getValidationErrorMessage = (value) => {
  const reg = /[^A-Za-z0-9,\-,_]/

  if (reg.test(value)) return 'Use apenas letras, números e traços.'

  return ''
}

const String = t.refinement(t.String, value => value.length > 0)

const Document = t.refinement(t.String, document => {
  const reg = /([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})/
  return reg.test(document) && (cpf.isValid(document) || cnpj.isValid(document))
})

Document.getValidationErrorMessage = (value, path) => {
  const reg = /([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})/

  if (!reg.test(value) || (!cpf.isValid(value) && !cnpj.isValid(value))) {
    if (path.includes('taxPayerId')) return 'Número de CPF inválido'

    if (path.includes('ein')) return 'Número de CNPJ inválido'

    return 'Documento inválido'
  }

  return ''
}

const CNPJ = t.refinement(t.String, document => {
  const reg = /([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})/
  return reg.test(document) && cnpj.isValid(document)
})

CNPJ.getValidationErrorMessage = (value) => {
  const reg = /([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})/

  if (!reg.test(value) || !cnpj.isValid(value)) {
    return 'Número de CNPJ inválido'
  }

  return ''
}

const CPF = t.refinement(t.String, document => {
  const reg = /([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})/

  return reg.test(document) && cpf.isValid(document)
})

CPF.getValidationErrorMessage = (value) => {
  const reg = /([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})/

  if (!reg.test(value) || !cpf.isValid(value)) {
    return 'Número de CPF inválido'
  }

  return ''
}

const Date = t.refinement(t.Date, date => {
  return moment.isDate(date)
})

const OpeningDate = t.refinement(t.Date, date => {
  return moment.isDate(date) && moment(date).isBefore(moment())
})

OpeningDate.getValidationErrorMessage = (value) => {
  if (!moment.isDate(value)) return 'Data inválida'

  if (moment(value).isAfter(moment())) return 'Opa! Como são as coisas aí no futuro? :)'

  return ''
}

const BirthDate = t.refinement(t.Date, date => {
  const dateValue = moment(date)
  const date18YearsAgo = moment().subtract(18, 'years')

  return moment.isDate(date) && dateValue.isBefore(date18YearsAgo)
})

BirthDate.getValidationErrorMessage = (value) => {
  const dateValue = moment(value).isValid() && moment(value)
  const date18YearsAgo = moment().subtract(18, 'years')

  if (!moment.isDate(dateValue)) return 'Você digitou uma data válida?'

  if (dateValue.isAfter(moment())) return 'Opa! Como são as coisas aí no futuro? :)'

  if (dateValue.isAfter(date18YearsAgo)) return 'Você tem mais de 18 anos?'

  return ''
}

Date.getValidationErrorMessage = (value) => {
  if (!moment.isDate(value)) return 'Data inválida'

  return ''
}

const Phone = t.refinement(t.String, phone => {
  const reg = /^(?:(55\d{2})|\d{2})[6-9]?\d{8}$/
  const value = phone.replace(/[^0-9]+/g, '')
  return reg.test(value)
})

Phone.getValidationErrorMessage = (value) => {
  const reg = /^(?:(55\d{2})|\d{2})[6-9]?\d{8}$/
  if (!reg.test(value)) return 'Telefone inválido'

  return ''
}

const Password = t.refinement(t.String, password => {
  const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/
  return reg.test(password)
})

Password.getValidationErrorMessage = (value) => {
  const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/

  if (!reg.test(value)) return 'Senha inválida'

  return ''
}

const NumberAndText = t.refinement(t.String, text => {
  const reg = /[A-Za-z0-9]+/
  return reg.test(text)
})

let MaxValue = (maxValue) => {
  return t.refinement(t.String, text => {
    return parseFloat(text) > 0 && parseFloat(text) <= parseFloat(maxValue)
  })
}

MaxValue.getValidationErrorMessage = (value) => {
  if (!parseFloat(value) > 0) {
    return 'Você esqueceu de digitar o valor? '
  }

  return 'Este valor é maior que o valor da venda'
}

let MinValue = (minValue) => {
  return t.refinement(t.String, text => {
    return parseFloat(text) > 0 && parseFloat(text) >= parseFloat(minValue)
  })
}

MinValue.getValidationErrorMessage = (value) => {
  if (!parseFloat(value) > 0) {
    return 'Você esqueceu de digitar o valor? '
  }

  return 'Este valor é menor que o valor mínimo'
}

let Number = t.refinement(t.String, text => {
  const reg = /^[0-9]*$/
  return reg.test(text) && text.length > 0
})

Number.getValidationErrorMessage = (value) => {
  const numberReg = /[0-9]*$/
  if (numberReg.test(value) || value.length === 0) {
    return 'Deve ser passado um valor numérico'
  }

  return ''
}

let CEP = t.refinement(t.String, text => {
  const reg = /[0-9]{8}$/
  return reg.test(text)
})

CEP.getValidationErrorMessage = (value) => {
  const cepReg = /[0-9]{8}/
  if (!cepReg.test(value)) {
    return 'CEP inválido'
  }

  return ''
}

let Token = t.refinement(t.String, text => {
  const reg = /[0-9]{8}$/
  return reg.test(text)
})

Token.getValidationErrorMessage = (value) => {
  if (value.length < 8) {
    return 'Você digitou um código com 8 dígitos?'
  }
  const tokenReg = /[0-9]{8}/
  if (!tokenReg.test(value)) {
    return 'Você digitou um código válido?'
  }

  return ''
}

const TypeAccount = () => {
  let types = {}
  AccountTypes.map(item => {
    types[item.value] = item.label
  })

  return t.enums(types)
}

const State = () => {
  let types = {}
  StateTypes.map(item => {
    types[item.value] = item.label
  })

  return t.enums(types)
}

const List = t.refinement(t.list(t.String), value => value && Array.isArray(value) && value.length > 0)

export default {
  Name,
  Email,
  String,
  Document,
  Date,
  Phone,
  Password,
  NumberAndText,
  Number,
  Token,
  CPF,
  CNPJ,
  OpeningDate,
  BirthDate,
  CEP,
  State,
  TypeAccount,
  Slug,
  List,
  MaxValue,
  MinValue
}
