import moment from 'moment'
import { toPhone } from './Utils'

export function getTransactionStatus(transaction) {
  const entryModesToConsiderConfirmedParam = [
    'chip',
    'magstripe',
    'magstripe_fallback',
    'contactless_chip',
    'contactless_tap'
  ]
  let status = transaction.status
  if (
    entryModesToConsiderConfirmedParam.includes(
      transaction.point_of_sale?.entry_mode
    )
  ) {
    if (transaction.confirmed === '0' && transaction.status === 'succeeded') {
      status = 'pending'
    }
  }
  return status
}

export const isAbleToCancel = (sale) => {
  if (isAbleToCancelNupay(sale)) {
    return true
  }

  let {
    created_at: createdAt,
    gateway_authorizer: gatewayAuthorizer,
    status,
    payment_type: paymentType
  } = sale

  const entryMode = sale && sale.point_of_sale && sale.point_of_sale.entry_mode

  const today = moment()
  createdAt = moment(createdAt)
  const after7Days = moment(createdAt).add(7, 'days')
  const after90Days = moment(createdAt).add(90, 'days')
  const after180Days = moment(createdAt).add(180, 'days')
  const after300Days = moment(createdAt).add(300, 'days')

  if (
    sale &&
    ((status === 'succeeded' && sale.confirmed === '1') ||
      status === 'pre_authorized')
  ) {
    if (
      (entryMode === 'manually_keyed' || entryMode === 'data_only') &&
      paymentType === 'credit'
    ) {
      if (['cielo'].includes(gatewayAuthorizer)) {
        return today.isBefore(after300Days)
      } else if (['rede', 'rede_pdv'].includes(gatewayAuthorizer)) {
        return today.isBetween(createdAt, after180Days, 'day', '[]')
      }
    } else if (
      ['chip', 'contactless_chip', 'contactless_tap'].includes(entryMode)
    ) {
      if (paymentType === 'credit') {
        return today.isBetween(createdAt, after180Days, 'day', '[]')
      } else if (paymentType === 'debit') {
        return today.isBetween(createdAt, after7Days, 'day', '[]')
      }
    }

    if (paymentType === 'pix') {
      return today.isBefore(after90Days)
    }
  }

  return false
}

export const isAbleToAuthorize = (sale) => {
  const createdAt = moment(sale.created_at)
  const today = moment()
  const diff = today.diff(createdAt, 'days')

  return (
    sale.payment_type === 'credit' &&
    sale.status === 'pre_authorized' &&
    diff <= 30
  )
}

export const isAbleToCancelBoleto = (sale) => {
  return sale.payment_type === 'boleto' && sale.status === 'pending'
}

export const isAbleToCancelNupay = (sale) => {
  return sale.payment_type === 'nupay' && sale.status !== 'canceled'
}

export const isAbleToPartialCancel = (sale) => {
  if (isAbleToCancelNupay(sale)) {
    return true
  }

  if (isPaymentTypePix(sale)) {
    return true
  }

  if (sale && sale.status === 'succeeded') {
    let {
      created_at: createdAt,
      gateway_authorizer: gatewayAuthorizer,
      payment_type: paymentType,
      point_of_sale: pointOfSale
    } = sale
    const entryMode = pointOfSale?.entry_mode
    const today = moment()
    createdAt = moment(createdAt)
    const after1Days = moment(createdAt).add(1, 'days')
    const after180Days = moment(createdAt).add(180, 'days')
    const after300Days = moment(createdAt).add(300, 'days')
    const validEntryModes = [
      'manually_keyed',
      'contactless_tap',
      'chip',
      'contactless_chip',
      'magstripe',
      'data_only'
    ]

    if (validEntryModes.includes(entryMode) && paymentType === 'credit') {
      if (['rede', 'rede_pdv'].includes(gatewayAuthorizer)) {
        return today.isBetween(after1Days, after180Days, 'day', '[]')
      }

      if (['cielo'].includes(gatewayAuthorizer)) {
        return today.isBefore(after300Days, 'day')
      }
    }
  }
  return false
}

export function isEligibleToCancelledLetter(history) {
  return history.some(
    (entry) =>
      (entry.operation_type === 'void' ||
        entry.operation_type === 'void_partial') &&
      entry.status === 'succeeded'
  )
}

export function isProcessingVoid(history) {
  return history.some(
    (entry) => entry.operation_type === 'void' && entry.status === 'pending'
  )
}

export const isPaymentTypeCard = (sale) => {
  return (
    sale.payment_type === 'credit' ||
    sale.payment_type === 'debit' ||
    sale.payment_type === 'voucher'
  )
}

export const isPaymentTypeBoleto = (sale) => {
  return sale.payment_type === 'boleto'
}

export const isPaymentTypePix = (sale) => {
  return sale.payment_type === 'pix'
}

export const isPaymentTypeWallet = (sale) => {
  return sale.payment_type === 'wallet'
}

export const isPaymentTypeNuPay = (sale) => {
  return sale.payment_type === 'nupay'
}

export const hasReceipt = (sale) => {
  return (
    sale.payment_type !== 'wallet' &&
    sale.payment_type !== 'boleto' &&
    sale.payment_type !== 'bolepix' &&
    sale.status === 'succeeded'
  )
}

export const getFormattedKeyType = (keyType) =>
  ({
    email: 'E-mail',
    phone: 'Telefone',
    national_registration: 'Documento',
    evp: 'Chave aleatória'
  })[keyType]

export const getFormattedKey = (type, key) => {
  if (type === 'national_registration') {
    return key?.toDocument()
  }
  if (type === 'phone') {
    return toPhone(key)
  }
  return key
}

export const isAbleToCancelByUser = (listOfAllowedUsers, userId) => {
  if (listOfAllowedUsers === undefined) {
    return true
  }

  const allowedUsers = listOfAllowedUsers?.split(',').map((i) => i.trim())
  const isUserAllowed = allowedUsers?.includes(userId)
  return isUserAllowed
}
