import t from 'tcomb-form'
import { mapValues } from 'lodash'

export const getFormOptionsWithValidation = (options, values, struct) => {
  const getFieldValidation = (fieldName, field) => {
    let message = 'Você esqueceu de preencher aqui?'

    if (field && field.config) {
      if (field.config.emptyMessage && values[fieldName] === '') {
        message = field.config.emptyMessage
      }

      if (field.config.maxValue) {
        if (parseFloat(values[fieldName]) === 0) {
          return {
            message: 'Você esqueceu de digitar o valor? '
          }
        }

        if (parseFloat(values[fieldName]) > parseFloat(field.config.maxValue)) {
          return {
            message:
              field.config.errorMessage ||
              'Este valor é maior que o valor da venda'
          }
        }
      }

      if (field.config.minValue) {
        if (parseFloat(values[fieldName]) === 0) {
          return {
            message: 'Você esqueceu de digitar o valor? '
          }
        }

        if (parseFloat(values[fieldName]) < parseFloat(field.config.minValue)) {
          return {
            message:
              field.config.errorMessage ||
              'Este valor é menor que o valor da venda'
          }
        }
      }
    }

    const erro = t.validate(values, struct).errors.find((error) => {
      return error.path.includes(fieldName)
    })

    if (erro) {
      if (!values[fieldName]) {
        return {
          message
        }
      } else {
        return erro
      }
    }
  }

  options.fields = mapValues(options.fields, (field, key) => {
    const fieldValidation = getFieldValidation(key, field)

    if (field.config.bypassValidation && field.hasError === undefined) {
      return {
        ...field,
        hasError: fieldValidation && fieldValidation.hasOwnProperty('message'),
        error: fieldValidation && fieldValidation.message
      }
    }

    if (field.config.bypassValidation) {
      return field
    }

    return {
      ...field,
      hasError: fieldValidation && fieldValidation.hasOwnProperty('message'),
      error: fieldValidation && fieldValidation.message
    }
  })

  return options
}

export const getPasswordValidation = (password = '', newPassword = '') => {
  const lowerAndUppercaseRegexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).+$/
  const checkSpecialCharacters = /^(?=.*[@#$&*])[A-Za-z\d@#$&*]*$/
  const checkRepeatedCharacters = /^(?!.*(\w|\W)\1{1,}).+$/
  const checkMoreThanOneNumber = /[0-9]{1,}/
  let minCharactersStatus = ''
  let upperAndLowercaseCharactersStatus = ''
  let passwordsMatchStatus = ''
  let passwordSpecialCharacterStatus = ''
  let passwordRepeatedCharacterStatus = ''
  let passwordRepeatedDigitStatus = ''

  if (password.length === 0 && newPassword.length === 0) {
    minCharactersStatus = 'neutral'
    upperAndLowercaseCharactersStatus = 'neutral'
    passwordSpecialCharacterStatus = 'neutral'
    passwordRepeatedCharacterStatus = 'neutral'
    passwordRepeatedDigitStatus = 'neutral'
  } else {
    if (password.length < 12 && newPassword.length < 12)
      minCharactersStatus = 'error'
    else minCharactersStatus = 'success'

    if (
      !lowerAndUppercaseRegexp.test(password) &&
      !lowerAndUppercaseRegexp.test(newPassword)
    )
      upperAndLowercaseCharactersStatus = 'error'
    else upperAndLowercaseCharactersStatus = 'success'

    if (checkSpecialCharacters.test(password)) {
      passwordSpecialCharacterStatus = 'success'
    } else {
      passwordSpecialCharacterStatus = 'error'
    }

    if (checkRepeatedCharacters.test(password)) {
      passwordRepeatedCharacterStatus = 'success'
    } else {
      passwordRepeatedCharacterStatus = 'error'
    }

    if (checkMoreThanOneNumber.test(password)) {
      passwordRepeatedDigitStatus = 'success'
    } else {
      passwordRepeatedDigitStatus = 'error'
    }
  }

  if (password.length === 0 && newPassword.length === 0) {
    passwordsMatchStatus = 'neutral'
  } else if (password !== newPassword) {
    passwordsMatchStatus = 'error'
  } else {
    passwordsMatchStatus = 'success'
  }

  return {
    minCharactersStatus,
    upperAndLowercaseCharactersStatus,
    passwordsMatchStatus,
    passwordSpecialCharacterStatus,
    passwordRepeatedCharacterStatus,
    passwordRepeatedDigitStatus,
    isValid:
      minCharactersStatus === 'success' &&
      upperAndLowercaseCharactersStatus === 'success' &&
      passwordsMatchStatus === 'success' &&
      passwordSpecialCharacterStatus === 'success' &&
      passwordRepeatedCharacterStatus === 'success'
  }
}
